import { Box, Text, VStack } from '@chakra-ui/react'
import { Button } from '@opengovsg/design-system-react'
import Image from 'next/image'
import { routeByWindow, StaticRoutes } from '~/utils/RouterNavigation'
import noAccess from '~/assets/no-access.svg'
import { ErrorNavbar } from './ErrorNavbar'
import { MiniFooter } from '../Footer/MiniFooter'

export const ErrorCardWithMessage = ({
  customTitle,
  customMessage,
}: {
  customTitle?: string
  customMessage?: string
}) => {
  return (
    <>
      <ErrorNavbar />
      <VStack
        bg="base.canvas.brand-subtle"
        align="stretch"
        alignItems="center"
        spacing="2rem"
        h="calc(100dvh - 4rem)"
      >
        <Box
          w="100%"
          flexGrow={1}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Image
            aria-hidden
            src={noAccess}
            alt="No access image"
            priority
            style={{
              maxWidth: '100%',
            }}
          />
        </Box>
        <VStack
          flexDirection="column"
          justify="flex-start"
          align="center"
          spacing="1rem"
          textAlign="center"
          px="1rem"
          flexGrow={2}
        >
          <Text textStyle="h6-semibold" as="h1" whiteSpace="pre-line">
            {customTitle}
          </Text>
          <Text textStyle="body-1" whiteSpace="pre-line">
            {customMessage}
          </Text>
          <Button
            variant="link"
            onClick={() => routeByWindow(StaticRoutes.SIGN_IN)}
          >
            <Text decoration="underline" textUnderlineOffset="0.25rem">
              Back to sign in
            </Text>
          </Button>
        </VStack>
        <Box py="2rem">
          <MiniFooter />
        </Box>
      </VStack>
    </>
  )
}
