import { Box, Text, VStack } from '@chakra-ui/react'
import { Button } from '@opengovsg/design-system-react'
import Image from 'next/image'

import image404 from '~/assets/scene_lift.svg'
import { ErrorNavbar } from '~/components/ErrorBoundary/ErrorNavbar'
import { MiniFooter } from '~/components/Footer/MiniFooter'
import { StaticRoutes, routeByWindow } from '~/utils/RouterNavigation'

// https://nextjs.org/docs/advanced-features/custom-error-page
const Custom404 = () => {
  return (
    <>
      <ErrorNavbar />
      <VStack
        bg="base.canvas.brand-subtle"
        align="stretch"
        alignItems="center"
        spacing="2rem"
        h="calc(100dvh - 4rem)"
      >
        <Box
          w="100%"
          flexGrow={1}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Image
            aria-hidden
            src={image404}
            alt="No access image"
            priority
            style={{
              maxWidth: '100%',
            }}
          />
        </Box>
        <VStack
          flexDirection="column"
          justify="flex-start"
          align="center"
          spacing="1rem"
          textAlign="center"
          px="1rem"
          flexGrow={2}
        >
          <Text textStyle="h6-semibold" as="h1" whiteSpace="pre-line">
            This page could not be found
          </Text>
          <Button
            variant="link"
            onClick={() => routeByWindow(StaticRoutes.SIGN_IN)}
          >
            <Text decoration="underline" textUnderlineOffset="0.25rem">
              Back to sign in
            </Text>
          </Button>
        </VStack>
        <Box py="2rem">
          <MiniFooter />
        </Box>
      </VStack>
    </>
  )
}

export default Custom404
