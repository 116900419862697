import '@fontsource/ibm-plex-mono' // Import if using code textStyles.
import 'inter-ui/inter.css' // Strongly recommended.

import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { ThemeProvider } from '@opengovsg/design-system-react'
import type { AppProps, AppType } from 'next/app'
import { type NextPageWithLayout } from '~/lib/types'
import { DefaultLayout } from '~/templates/layouts/DefaultLayout'
import { theme } from '~/theme'
import { trpc } from '~/utils/trpc'
import { Provider } from 'jotai'
import Suspense from '~/components/Suspense/Suspense'
import ErrorBoundary from '~/components/ErrorBoundary/ErrorBoundary'
import { useRef } from 'react'
import { LoadingSpinner } from '~/features/list/components/LoadingSpinner'
import { usePageLoading } from '~/hooks/usePageLoading'
import { AppVersionModalBanner } from '~/components/AppRefreshBanner'
import { Box, Stack } from '@chakra-ui/react'
import { GrowthBookWrapper } from '~/Growthbook'
import { GlobalContextProvider } from '~/state/context'

type AppPropsWithAuthAndLayout = AppProps & {
  Component: NextPageWithLayout
}

const MyApp = ((props: AppPropsWithAuthAndLayout) => {
  const ref = useRef<HTMLDivElement>(null)
  const { isPageLoading } = usePageLoading()
  return (
    <ErrorBoundary>
      <GrowthBookWrapper>
        <Provider>
          <ThemeProvider
            theme={theme}
            resetCSS
            portalZIndex={40}
            toastOptions={{
              portalProps: {
                containerRef: ref,
              },
            }}
          >
            <Box ref={ref} />
            <GlobalContextProvider>
              <Stack spacing={0} minH="100dvh">
                <Suspense fallback={<LoadingSpinner />}>
                  <AppVersionModalBanner />
                  {isPageLoading ? (
                    <LoadingSpinner />
                  ) : (
                    <ChildWithLayout {...props} />
                  )}
                  {process.env.NODE_ENV !== 'production' && (
                    <ReactQueryDevtools initialIsOpen={false} />
                  )}
                </Suspense>
              </Stack>
            </GlobalContextProvider>
          </ThemeProvider>
        </Provider>
      </GrowthBookWrapper>
    </ErrorBoundary>
  )
}) as AppType

// This is needed so suspense will be triggered for anything within the LayoutComponents which uses useSuspenseQuery
const ChildWithLayout = ({
  Component,
  pageProps,
}: AppPropsWithAuthAndLayout) => {
  const getLayout =
    Component.getLayout ?? ((page) => <DefaultLayout>{page}</DefaultLayout>)
  return (
    <ErrorBoundary>{getLayout(<Component {...pageProps} />)}</ErrorBoundary>
  )
}

export default trpc.withTRPC(MyApp)
