import { Container, ContainerProps, Spinner } from '@chakra-ui/react'

export const LoadingSpinner = ({ ...rest }: ContainerProps) => {
  return (
    <Container
      display="flex"
      h="100vh"
      w="100vw"
      justifyContent="center"
      alignItems="center"
      {...rest}
    >
      <Spinner color="interaction.main.default" fontSize="2rem" />
    </Container>
  )
}
