import { Item } from '@prisma/client'
import { MemoizeBySections } from '~/utils/decorators/MemoizeBySection'
import { RecordView } from './RecordItemView'

export interface RecordCardContent {
  record?: RecordView
  item: Item
}

export class ItemsetCardContent {
  private readonly recordsCardContentInstance: RecordCardContent[]
  private readonly tagInstance: string
  constructor(recordsCardContent: RecordCardContent[], tag: string) {
    this.recordsCardContentInstance = recordsCardContent
    this.tagInstance = tag
  }
  get recordsCardContent() {
    return this.recordsCardContentInstance.sort(
      (
        {
          item: {
            metadata: { positionInsideParent: firstPosition },
          },
        },
        {
          item: {
            metadata: { positionInsideParent: secondPosition },
          },
        },
      ) => firstPosition - secondPosition,
    )
  }
  get tag() {
    return this.tagInstance
  }

  @MemoizeBySections()
  public getVerifiedNestedItems = () => {
    return this.recordsCardContent.filter(
      (recordCardContent) => recordCardContent.record?.isVerified,
    )
  }

  public areAllItemsVerified = () => {
    return this.recordsCardContent.every(({ record }) => record?.isVerified)
  }
}
