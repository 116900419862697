import { datadogRum } from '@datadog/browser-rum'
import { MeUser } from '~/server/modules/me/types'

export const datadogUsedForMonitoring = (): boolean => {
  return (
    process.env.NEXT_PUBLIC_DD_ENV === 'production' ||
    process.env.NEXT_PUBLIC_DD_ENV == 'staging' ||
    process.env.NEXT_PUBLIC_DD_ENV == 'uat'
  )
}

export const isDatadogInitialized = (): boolean => {
  return datadogRum.getInitConfiguration() !== undefined
}

export const setDatadogUser = (user: MeUser) => {
  const { userTeams: _, ...rest } = user
  if (datadogUsedForMonitoring() && !Object.keys(datadogRum.getUser()).length)
    datadogRum.setUser(rest)
}
