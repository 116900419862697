import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const helpers = createMultiStyleConfigHelpers(['root'])

// add the !important modifier since the stack rendering the cards
// has set a spacing of 0rem between adjacent cards

export const ItemsetWrapper = helpers.defineMultiStyleConfig({
  baseStyle: {
    root: {
      margin: '0rem',
    },
  },
  variants: {
    expanded: {
      root: {
        boxShadow: 'md',
        marginTop: '1rem !important',
        marginBottom: '1rem !important',
      },
    },
  },
})
