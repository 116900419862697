import { defineStyleConfig } from '@chakra-ui/react'
import { MOBILE_NAVBAR_HEIGHT } from '~/constants'
import { STICKY_HEADER_Z_INDEX } from '~/constants/zIndex'

export const MobileNavbar = defineStyleConfig({
  baseStyle: {
    display: 'flex',
    justifyContent: 'space-between',
    bg: 'slate.900',
    position: 'sticky',
    top: 0,
    h: MOBILE_NAVBAR_HEIGHT,
    alignItems: 'center',
    textStyle: 'subhead-1',
    color: 'base.content.inverse',
    textDecoration: 'none',
    zIndex: STICKY_HEADER_Z_INDEX,
    '& #primary-nav': {
      alignItems: 'center',
    },

    '& svg': {
      boxSize: '1.5rem',
      cursor: 'pointer',
    },

    '& #primary-icon': {
      mr: '1rem',
    },

    '& #secondary-nav': {
      cursor: 'pointer',
    },

    '& #secondary-icon': {
      mr: '0.375rem',
    },

    '& #navbar-info': {
      flexDir: 'column',
      alignItems: 'center',
    },

    '& #navbar-subheading': {
      textStyle: 'caption-1',
    },
  },
  variants: {
    overlay: {
      bg: 'base.canvas.overlay',
    },
  },
})
