import { createMultiStyleConfigHelpers } from '@chakra-ui/react'
import { ALERT_INFOBOX_HEIGHT, MOBILE_NAVBAR_HEIGHT } from '~/constants'

const helpers = createMultiStyleConfigHelpers([
  'stickyCard',
  'heading',
  'itemsetCheckbox',
  'highlight',
  'name',
  'itemsetButton',
  'dropdown',
  'clickable',
])

export const ItemsetCard = helpers.defineMultiStyleConfig({
  variants: {
    alertShown: {
      stickyCard: {
        top: `calc(${MOBILE_NAVBAR_HEIGHT} + ${ALERT_INFOBOX_HEIGHT})`,
      },
    },
  },
  baseStyle: {
    stickyCard: {
      position: 'sticky',
      left: 0,
      top: MOBILE_NAVBAR_HEIGHT,
    },
    heading: {
      pl: 'auto',
      flexDir: 'row',
      alignItems: 'flex-start',
    },
    itemsetCheckbox: {
      maxW: '1.5rem',
      p: 0,
      ml: '1rem',
    },
    name: {
      textStyle: 'subhead-1',
      justify: 'space-between',
      flexDir: 'row',
      w: '100%',
    },
    itemsetButton: {
      h: 'fit-content',
      color: 'slate.500',
      '& svg': {
        boxSize: '1rem',
      },
      minH: 0,
      minW: 0,
    },
    dropdown: {
      cursor: 'pointer',
      '& .dropdown-prompt': {
        textStyle: 'body-2',
        flex: 1,
        textAlign: 'left',
        color: 'base.content.default',
      },
    },
    clickable: {
      _active: {
        WebkitTapHighlightColor: 'transparent',
      },
      position: 'absolute',
      top: '0',
      left: '0',
      w: '80%',
      h: '100%',
      cursor: 'pointer',
    },
  },
})
