import p from '../../package.json'
import { datadogRum } from '@datadog/browser-rum'
import { observable } from '@trpc/server/observable'
import { httpLink, loggerLink, TRPCClientError, TRPCLink } from '@trpc/client'
import { createTRPCNext } from '@trpc/next'
import { inferRouterInputs, inferRouterOutputs } from '@trpc/server'
import { TRPC_ERROR_CODE_KEY } from '@trpc/server/rpc'
import { NextPageContext } from 'next'
import { APP_VERSION_HEADER_KEY, REQUIRE_UPDATE_EVENT } from '~/constants'
// ℹ️ Type-only import:
// https://www.typescriptlang.org/docs/handbook/release-notes/typescript-3-8.html#type-only-imports-and-export
import type { AppRouter } from '~/server/modules/_app'
import { TRPCWithErrorCodeSchema } from './error'
import { getBaseUrl } from './getBaseUrl'
import { toast } from './toast'
import { isAbortError } from 'next/dist/server/pipe-readable'
import { transformer } from './transformer'

const NON_RETRYABLE_ERROR_CODES: Set<TRPC_ERROR_CODE_KEY> = new Set([
  'FORBIDDEN',
  'NOT_FOUND',
])

const isErrorRetryableOnClient = (error: unknown): boolean => {
  if (typeof window === 'undefined') return true // when using SSR to fetch queries
  if (!(error instanceof TRPCClientError)) return true // catch-all for general errors thrown inside getServerSideProps etc
  const res = TRPCWithErrorCodeSchema.safeParse(error)
  if (res.success && res.data.code === 'UNAUTHORIZED') {
    return false
  }
  if (res.success && NON_RETRYABLE_ERROR_CODES.has(res.data.code)) return false
  return true
}

export const appLink: TRPCLink<AppRouter> = () => {
  return ({ next, op }) => {
    return observable((observer) => {
      const unsubscribe = next(op).subscribe({
        next(value) {
          if (!value.context) {
            return observer.next(value)
          }
          // TODO: Can this be typed better?
          const response = value.context.response as
            | Partial<Response> // Looser type for caution
            | undefined
          if (!response) {
            return observer.next(value)
          }
          const headers = response.headers
          if (!headers) {
            return observer.next(value)
          }
          const serverVersion = headers.get(APP_VERSION_HEADER_KEY)
          if (!serverVersion) {
            return observer.next(value)
          }
          const clientVersion = p.version
          if (clientVersion !== serverVersion) {
            window.dispatchEvent(new Event(REQUIRE_UPDATE_EVENT))
          }
          return observer.next(value)
        },
        error(err) {
          if (
            (err instanceof TRPCClientError && isAbortError(err.cause)) ||
            isAbortError(err)
          ) {
            // if the error is thrown because the request is aborted,
            // there is no need to forward the error any further
            return observer.complete()
          }
          observer.error(err)
        },
        complete() {
          observer.complete()
        },
      })
      return unsubscribe
    })
  }
}

/**
 * Extend `NextPageContext` with meta data that can be picked up by `responseMeta()` when server-side rendering
 */
export interface SSRContext extends NextPageContext {
  /**
   * Set HTTP Status code
   * @example
   * const utils = trpc.useContext();
   * if (utils.ssrContext) {
   *   utils.ssrContext.status = 404;
   * }
   */
  status?: number
}

/**
 * A set of strongly-typed React hooks from your `AppRouter` type signature with `createReactQueryHooks`.
 * @link https://trpc.io/docs/react#3-create-trpc-hooks
 */
export const trpc = createTRPCNext<
  AppRouter,
  SSRContext,
  'ExperimentalSuspense'
>({
  config({ ctx }) {
    /**
     * If you want to use SSR, you need to use the server's full URL
     * @link https://trpc.io/docs/ssr
     */
    return {
      abortOnUnmount: true,
      /**
       * @link https://trpc.io/docs/data-transformers
       */
      transformer,
      /**
       * @link https://trpc.io/docs/links
       */
      links: [
        // sends the version number from the client to the server, and handles catching abortErrors
        appLink,
        // adds pretty logs to your console in development and logs errors in production
        loggerLink({
          enabled: () => process.env.NODE_ENV === 'development',
        }),
        httpLink({
          url: `${getBaseUrl()}/api/trpc`,
          /**
           * Provide a function that will invoke the current global
           * window.fetch. We do this to pick up the wrapped fetch
           * injected by Datadog at runtime
           */
          fetch: (...args) => fetch(...args),
          /**
           * Set custom request headers on every request from tRPC
           * @link https://trpc.io/docs/ssr
           */
          headers() {
            if (ctx?.req) {
              // To use SSR properly, you need to forward the client's headers to the server
              // This is so you can pass through things like cookies when we're server-side rendering

              // If you're using Node 18, omit the "connection" header
              const { connection: _connection, ...headers } = ctx.req.headers
              return {
                ...headers,
                [APP_VERSION_HEADER_KEY]: p.version,
                // Optional: inform server that it's an SSR request
                'x-ssr': '1',
              }
            }
            return {
              [APP_VERSION_HEADER_KEY]: p.version,
            }
          },
        }),
      ],
      /**
       * @link https://react-query.tanstack.com/reference/QueryClient
       */
      queryClientConfig: {
        defaultOptions: {
          queries: {
            useErrorBoundary: true,
            staleTime: 1000 * 10, // 10 seconds
            retry: (failureCount: number, error: unknown) => {
              if (!isErrorRetryableOnClient(error)) return false
              return failureCount < 3
            },
          },
          // mutations should redirect to sign in if unauthorized to access the current resource
          // do not use the error boundary to catch these errors, since we don't want to unmount the component
          // instead, just log directly to datadog
          mutations: {
            useErrorBoundary: false,
            onError: (error) => {
              datadogRum.addError(error)
              if (error instanceof TRPCClientError) {
                const res = TRPCWithErrorCodeSchema.safeParse(error)
                if (res.success) {
                  toast({ title: res.data.message, status: 'error' })
                  return
                } else {
                  toast({ title: 'Something went wrong', status: 'error' })
                }
              }
            },
          },
        },
      },
      ssr: false,
    }
  },
})

export type RouterInput = inferRouterInputs<AppRouter>
export type RouterOutput = inferRouterOutputs<AppRouter>
