import superjson from 'superjson'

// follow a design pattern like https://github.com/trpc/trpc/issues/4790
// to ensure the classes are registered properly

superjson.registerCustom<Buffer, number[]>(
  {
    isApplicable: (v): v is Buffer => v instanceof Buffer,
    serialize: (v) => [...v],
    deserialize: (v) => Buffer.from(v),
  },
  'buffer',
)

export const transformer = superjson
