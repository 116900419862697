// Some of the admin routes currently have heavy lifting done in getServerSideProps
// While this is happening, no fallback is displayed

import Router from 'next/router'
import { useEffect, useState } from 'react'

export const usePageLoading = () => {
  const [isPageLoading, setIsPageLoading] = useState(false)

  useEffect(() => {
    const routeEventStart = () => {
      setIsPageLoading(true)
    }
    const routeEventEnd = () => {
      setIsPageLoading(false)
    }

    Router.events.on('routeChangeStart', routeEventStart)
    Router.events.on('routeChangeComplete', routeEventEnd)
    Router.events.on('routeChangeError', routeEventEnd)
    return () => {
      Router.events.off('routeChangeStart', routeEventStart)
      Router.events.off('routeChangeComplete', routeEventEnd)
      Router.events.off('routeChangeError', routeEventEnd)
    }
  }, [])

  return { isPageLoading }
}
