import { datadogRum } from '@datadog/browser-rum'
import { useRouter } from 'next/router'
import { ListLookupView } from '~/models'
import { trpc } from '~/utils/trpc'
import { useEffect, useMemo } from 'react'
import { StaticRoutes } from '~/utils/RouterNavigation'
import { datadogUsedForMonitoring } from '~/monitoring/utils'

/**
 * Send RUM logs to datadog. It converts the query params into the appropriate section names (or item name),
 * and starts the view based on the current pathname
 */
export const useDatadogWithViewName = (): void => {
  const { asPath, query } = useRouter()
  const { listId, sections } = query
  // do NOT use useSuspenseQuery, we want this running in the background and enabled only for a valid listId
  // if this fails, do NOT use the error boundary, since we want the original query callsite (in the submission container) to provide
  // the stack trace
  const { data: lookupData } = trpc.list.lookup.useQuery(
    { listId: listId as string },
    { enabled: Boolean(listId), useErrorBoundary: false },
  )
  const viewName = useMemo(() => {
    if (!lookupData) {
      const currentScreen = Object.keys(StaticRoutes).find((screen) =>
        asPath.includes(StaticRoutes[screen as keyof typeof StaticRoutes]),
      )
      return currentScreen ?? null
    }
    const listLookupView = new ListLookupView(lookupData)
    if (sections && sections.length) {
      const views = listLookupView.getSectionViewsById(sections as string[])
      return [
        listLookupView.rootList.name,
        ...views.map((view) => view.name),
      ].join(' > ')
    } else {
      return listLookupView.rootList.name
    }
  }, [asPath, lookupData, sections])

  useEffect(() => {
    if (datadogUsedForMonitoring()) {
      if (viewName) datadogRum.startView({ name: viewName })
    }
  }, [viewName])
}
