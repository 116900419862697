import { tabsAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'
import { textStyles } from '../foundations/textStyles'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tabsAnatomy.keys)

const variantBrand = definePartsStyle({
  tablist: {
    width: 'inherit',
    mx: '-1rem',
    px: '1rem',
    overflowX: 'none',
    borderBottomWidth: '2px',
    borderBottomStyle: 'solid',
    borderColor: 'inherit',
  },
  tab: {
    mb: '-2px',
    textTransform: 'none',
    color: 'interaction.support.unselected',
    ...textStyles['subhead-1'],
    _selected: {
      textTransform: 'none',
      color: 'interaction.main.default',
      borderBottomWidth: '2px',
      borderColor: 'interaction.main.default',
    },
  },
})

export const Tabs = defineMultiStyleConfig({
  variants: { brand: variantBrand },
})
