import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const helpers = createMultiStyleConfigHelpers(['container', 'icon'])

export const AdminNavbar = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      justifyContent: 'space-between',
      alignItems: 'center',
      bg: 'slate.900',
      borderBottomWidth: '1px',
      borderColor: 'base.divider.medium',
      w: '100%',
    },
    icon: {
      verticalAlign: 'middle',
      boxSize: '4rem',
      blockSize: '4rem',
      fontSize: '1rem',
      p: '1rem',
    },
    menuButton: {
      minH: '100%',
      color: 'standard.white',
    },
    menuList: {
      zIndex: '5',
      bg: 'interaction.muted.main.hover',
      pt: '0',
    },
    menuGroup: {
      my: '0.5rem',
    },
  },
})
