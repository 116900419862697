import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const helpers = createMultiStyleConfigHelpers([
  'root',
  'heading',
  'itemInfo',
  'itemCheckbox',
  'name',
  'labels',
  'labelIcon',
  'description',
  'clickable',
])

export const ItemRecordCard = helpers.defineMultiStyleConfig({
  baseStyle: {
    root: {
      p: '1rem',
    },
    heading: {
      pl: 'auto',
      flexDir: 'row',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      w: '100%',
    },
    itemInfo: {
      alignItems: 'center',
    },
    itemCheckbox: {
      maxW: '1.5rem',
      p: 0,
      ml: '1rem',
    },
    name: {
      textStyle: 'subhead-1',
      justify: 'space-between',
      flexDir: 'row',
      w: '100%',
    },
    issueInfo: {
      maxW: '100%',
    },
    issueHashtag: {
      textColor: 'blue.400',
      textStyle: 'body-1',
    },
    issueComment: {
      textColor: 'base.content.medium',
      textStyle: 'body-1',
      ml: '0.3rem',
      wordBreak: 'break-all',
    },
    clickable: {
      _active: {
        WebkitTapHighlightColor: 'transparent',
      },
      position: 'absolute',
      top: '0',
      left: '0',
      w: '80%',
      h: '100%',
      cursor: 'pointer',
    },
  },
})
