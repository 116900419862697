import { inferRouterOutputs } from '@trpc/server'
import { Lookup } from '~/server/modules/list/types'
import { RecordRouter } from '~/server/modules/record/record.router'

type ItemRecord = Lookup['submissions'][number]['records'][number]

export class RecordDetails {
  protected readonly recordInstance: ItemRecord
  constructor(
    record: inferRouterOutputs<RecordRouter>['getRecordItemByUniqueIdentifier'],
  ) {
    this.recordInstance = record
  }

  get record() {
    return this.recordInstance
  }

  get uniqueRecordIdentifier() {
    return this.record.uniqueRecordIdentifier
  }

  get itemName() {
    return this.recordInstance.item.name
  }

  get itemDescription() {
    return this.recordInstance.item.description
  }

  get id() {
    return this.record.id
  }
}

export class RecordView extends RecordDetails {
  constructor(record: ItemRecord) {
    super(record)
  }
  get item() {
    return this.record.item
  }
  get uniqueItemIdentifier() {
    return this.record.item.uniqueItemIdentifier
  }
  get record() {
    return this.recordInstance
  }
  get submitter() {
    return this.record.submitter
  }
  get actualQuantity() {
    return this.record.actualQuantity
  }
  set actualQuantity(a: number | null) {
    this.record.actualQuantity = a
  }
  get comment() {
    return this.record.comment
  }
  set comment(c: string | null) {
    this.record.comment = c
  }
  get images() {
    return this.record.images
  }
  get uniqueRecordIdentifier() {
    return this.record.uniqueRecordIdentifier
  }

  get id() {
    return this.record.id
  }

  get createdAt() {
    return this.record.createdAt
  }

  get verifiedAt() {
    return this.record.verifiedAt
  }

  set verifiedAt(ts: Date | null) {
    this.record.verifiedAt = ts
  }

  get isVerified() {
    return this.record.verifiedAt
  }
}
