import { Flex, Link } from '@chakra-ui/react'
import Image from 'next/image'
import NextLink from 'next/link'
import React from 'react'
import armouryLogo from '~/assets/armoury-logo-dark.svg'
import { StaticRoutes } from '~/utils/RouterNavigation'

export const ErrorNavbar = (): JSX.Element => {
  return (
    <Flex
      justify="space-between"
      align="center"
      bg="slate.900"
      borderBottomWidth="1px"
      borderColor="base.divider.medium"
      h="4rem"
    >
      <Link p="1rem" as={NextLink} href={StaticRoutes.SIGN_IN}>
        <Image src={armouryLogo} alt="Armoury Logo" priority />
      </Link>
    </Flex>
  )
}
