import { useCallback } from 'react'
import Router from 'next/router'
import { trpc } from '~/utils/trpc'
import { setDatadogUser } from '~/monitoring/utils'

export const useUser = () => {
  const [user] = trpc.me.get.useSuspenseQuery(undefined, {
    onSuccess: (res) => {
      setDatadogUser(res)
    },
  })

  const logoutMutation = trpc.auth.logout.useMutation()

  const logout = useCallback(
    (redirectToSignIn = true) => {
      return logoutMutation.mutate(undefined, {
        onSuccess: () => {
          if (redirectToSignIn) Router.push('/sign-in')
        },
      })
    },
    [logoutMutation],
  )

  return { user, logout }
}
