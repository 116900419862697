import p from '../../package.json'
import { datadogRum } from '@datadog/browser-rum'
import { datadogUsedForMonitoring, isDatadogInitialized } from './utils'
import { browserEnv } from '~/browserEnv'

export const realUserMonitoring = () => {
  if (
    !browserEnv.NEXT_PUBLIC_DATADOG_APPLICATION_ID ||
    !browserEnv.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN
  ) {
    console.warn(
      'Datadog RUM is disabled. Provide an applicationId and clientId.',
    )
    return
  }
  if (!isDatadogInitialized() && datadogUsedForMonitoring()) {
    datadogRum.init({
      applicationId: browserEnv.NEXT_PUBLIC_DATADOG_APPLICATION_ID,
      clientToken: browserEnv.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN,
      site: 'datadoghq.com',
      service: browserEnv.NEXT_PUBLIC_APP_NAME,
      env: browserEnv.NEXT_PUBLIC_DD_ENV,
      version: p.version,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
      trackViewsManually: true,
      traceSampleRate: 100,
      allowedTracingUrls: [() => true],
    })
    datadogRum.startSessionReplayRecording()
  }
}
