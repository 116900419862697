/**
 * Retrieves the base URL for the current environment.
 * @note Server-only utility function.
 */
export const getBaseUrl = () => {
  if (typeof window !== 'undefined') {
    return ''
  }
  // reference for server
  if (process.env.PUBLIC_APP_URL) {
    return `https://${process.env.PUBLIC_APP_URL}`
  }
  // assume localhost
  return `http://localhost:${process.env.PORT ?? 3000}`
}
