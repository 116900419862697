import { memoize } from 'lodash'
import { BiSolidErrorCircle } from 'react-icons/bi'
import { DropdownOptionWithIcon } from '~/components/Select'
import { GetListStates } from '~/server/modules/list/types'

export enum ItemState {
  GoodState = 'GoodState',
  BadState = 'BadState',
}

export type SubmitItemState = {
  state: ItemState
  value: string | null
}

export class ListTemplateStatesView {
  private readonly statesInstance: GetListStates
  private readonly onlyShowOtherStates: boolean

  constructor(states: GetListStates, onlyShowOtherStates = false) {
    this.statesInstance = states
    this.onlyShowOtherStates = onlyShowOtherStates
  }

  private getDropdownOptionsHelper = memoize(
    (states: string[], icon: () => JSX.Element): DropdownOptionWithIcon[] => {
      return states.map((state) => {
        return {
          value: state,
          label: state,
          icon: icon,
        }
      })
    },
    (states) => {
      return states.join('')
    },
  )

  public getDropdownOptions = (): DropdownOptionWithIcon[] => {
    return this.getDropdownOptionsHelper(this.states.otherStates, () => (
      <BiSolidErrorCircle
        size="20px"
        color="var(--chakra-colors-interaction-warning-default)"
      />
    ))
  }

  public getSelectedOption = (
    targetValue: string | null,
  ): DropdownOptionWithIcon => {
    return (
      this.getDropdownOptions().find(
        (option) => option.value === targetValue,
      ) || (this.getDropdownOptions().at(0) as DropdownOptionWithIcon)
    )
  }

  public determineItemState = (value: string | null): SubmitItemState => {
    if (!value || !this.otherStates.includes(value)) {
      return this.getDefaultOtherState()
    }
    return {
      state: ItemState.BadState,
      value,
    }
  }

  private getDefaultOtherState = (): SubmitItemState => {
    if (!this.states.otherStates.length)
      throw new Error('OtherStates must have a length of greater than 1')
    return {
      state: ItemState.BadState,
      value: this.states.otherStates[0] as string,
    }
  }

  get otherStates() {
    return this.states.otherStates
  }
  get annotations() {
    return this.states.annotations
  }
  get states() {
    return this.statesInstance
  }
}
