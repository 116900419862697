import { Button, ButtonProps, HStack, VStack } from '@chakra-ui/react'
import { PropsWithChildren } from 'react'

interface StickyButton extends ButtonProps {
  label: string
}

export const STICKY_BUTTON_HEIGHT = '5.5rem'

export const StickyButton = ({ label, ...rest }: StickyButton) => {
  return (
    <VStack
      h={STICKY_BUTTON_HEIGHT}
      position="fixed"
      left="0"
      bottom="0"
      align="stretch"
      w="100%"
    >
      <Button h="3.5rem" m="1rem" {...rest}>
        {label}
      </Button>
    </VStack>
  )
}

export const StickyFooter = ({ children }: PropsWithChildren) => {
  return (
    <VStack
      h={STICKY_BUTTON_HEIGHT}
      position="fixed"
      left="0"
      bottom="0"
      align="stretch"
      w="100%"
      borderWidth="1px 0 0 0"
      p="1rem"
    >
      <HStack spacing="1rem">{children}</HStack>
    </VStack>
  )
}
