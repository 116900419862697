import { PropsWithChildren, createContext, useContext, useState } from 'react'

type WindowExpiringContextType = {
  showWindowAlert: boolean
  setShowWindowAlert: (shown: boolean) => void
}

export const WindowExpiringContext =
  createContext<WindowExpiringContextType | null>(null)

export const WindowExpiringContextProvider = ({
  children,
}: PropsWithChildren) => {
  const [showWindowAlert, setShowWindowAlert] = useState(false)
  return (
    <WindowExpiringContext.Provider
      value={{ showWindowAlert, setShowWindowAlert }}
    >
      {children}
    </WindowExpiringContext.Provider>
  )
}

export const useWindowExpiring = () => {
  const context = useContext(WindowExpiringContext)
  if (!context) {
    throw new Error(
      'Can only use WindowExpiringContext inside a WindowExpiringContextProvider',
    )
  }
  const { showWindowAlert, setShowWindowAlert } = context
  return { showWindowAlert, setShowWindowAlert }
}
