import { Item } from '@prisma/client'
import { ListTemplateResponseDto } from '~/server/modules/list/list.util'
import { ItemsetCardContent, RecordCardContent } from './CardContentView'
import { ListLookupView } from './ListLookupView'
import { ListTemplateStatesView } from './ListTemplateStatesView'
import { RecordView } from './RecordItemView'

export class ListTemplateView {
  private readonly listTemplate: ListTemplateResponseDto
  public readonly listTemplateStates: ListTemplateStatesView
  constructor(listTemplate: ListTemplateResponseDto) {
    this.listTemplate = listTemplate
    this.listTemplateStates = new ListTemplateStatesView({
      otherStates: this.listTemplate.otherStates,
      annotations: this.listTemplate.annotations,
    })
  }

  get id() {
    return this.listTemplate.id
  }

  get sections() {
    return this.listTemplate.sections || []
  }

  get name() {
    return this.listTemplate.name
  }

  get items() {
    return this.listTemplate.items ?? []
  }

  get parentListTemplateId() {
    return this.listTemplate.parentListTemplateId
  }

  get description() {
    return this.listTemplate.description
  }

  get otherStates() {
    return this.listTemplate.otherStates
  }

  get annotations() {
    return this.listTemplate.annotations
  }

  public getNestedSections(
    sections: ListTemplateResponseDto[] = this.sections,
  ) {
    if (!this.sections || !this.sections.length) return []
    this.sections.forEach((section) => {
      const sectionView = new ListTemplateView(section)
      const nested = sectionView.getNestedSections()
      sections = [...sections, ...nested]
    })
    return sections
  }

  public getNestedItems(items: Item[] = this.items) {
    if (!this.sections || !this.sections.length) {
      return this.items
    }
    this.sections.forEach((section) => {
      const sectionView = new ListTemplateView(section)
      const nested = sectionView.getNestedItems()
      items = [...items, ...nested]
    })
    return items
  }

  public getItemsets = (
    sections: string[],
    listLookupView: ListLookupView,
  ): ItemsetCardContent[] => {
    const recordsByUniqueItemIdentifier =
      listLookupView.getRecordsInSubmissionByUniqueItemIdentifier(sections)
    const recordCardContentByTag: { [key: string]: RecordCardContent[] } = {}
    this.items
      .filter((item: Item): item is Item & { tag: string } => item.tag != null)
      .sort((a, b) => (b.name.toLowerCase() > a.name.toLowerCase() ? 1 : -1))
      .forEach((item) => {
        const record = recordsByUniqueItemIdentifier[
          item.uniqueItemIdentifier
        ] as RecordView
        recordCardContentByTag[item.tag] = [
          ...(recordCardContentByTag[item.tag] ?? []),
          { item, record },
        ]
      })
    return Object.keys(recordCardContentByTag).map(
      (tag): ItemsetCardContent => {
        const records = recordCardContentByTag[tag] as RecordCardContent[]
        return new ItemsetCardContent(records, tag)
      },
    )
  }

  public getRecordCardContentsWithoutTags = (
    sections: string[],
    listLookupView: ListLookupView,
  ) => {
    const recordsByUniqueItemIdentifier =
      listLookupView.getRecordsInSubmissionByUniqueItemIdentifier(sections)
    return (
      this.items
        .filter((item) => !item.tag)
        // sort by the item position inside the parent, for each section
        .sort(
          ({ metadata: firstItemPosition }, { metadata: secondItemPosition }) =>
            firstItemPosition.positionInsideParent -
            secondItemPosition.positionInsideParent,
        )
        .map((item) => {
          const record =
            recordsByUniqueItemIdentifier[item.uniqueItemIdentifier]
          return { record, item }
        })
    )
  }
}
