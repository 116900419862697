import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const helpers = createMultiStyleConfigHelpers(['root'])

export const BaseCard = helpers.defineMultiStyleConfig({
  baseStyle: {
    root: {
      flexDirection: 'column',
      position: 'relative',
      bgColor: 'standard.white',
      p: '1rem',
      m: 0,
      cursor: 'pointer',
      borderWidth: '1px',
      borderRadius: '0.5rem',
      borderColor: 'base.divider.medium',
      _active: { bgColor: 'base.canvas.brand-subtle' },
    },
  },
  variants: {
    itemset: {
      root: {
        mx: '-1rem !important',
        borderRadius: 0,
      },
    },
    expanded: {
      root: {
        mx: '-1rem !important',
        bgColor: 'brand.primary.100',
        borderRadius: 0,
      },
    },
    'checklist-card': {
      root: {
        mx: '-1rem !important',
        borderRadius: 0,
        borderWidth: '0.5px',
      },
    },
  },
})
