import {
  BreadcrumbItem,
  BreadcrumbItemProps,
  BreadcrumbLink,
} from '@chakra-ui/react'

export interface CrumbProps extends BreadcrumbItemProps {
  label: string
  last?: boolean
  onClick?: () => void
}
// Props are required as Breadcrumb parent injects props into its crumb children.
export const BreadcrumbItemWrapper = ({
  onClick,
  label,
  ...props
}: CrumbProps) => {
  return (
    <BreadcrumbItem {...props} display="inline">
      <BreadcrumbLink _hover={{ textDecoration: 'none' }} onClick={onClick}>
        {label}
      </BreadcrumbLink>
    </BreadcrumbItem>
  )
}
