import { extendTheme } from '@chakra-ui/react'
import { theme as ogpDsTheme } from '@opengovsg/design-system-react'
import { colors } from './foundations/colors'
import { shadows } from './foundations/shadows'
import { spacing } from './foundations/spacing'
import { textStyles } from './foundations/textStyles'
import { typography } from './typography'

import * as components from './components'
import * as features from './features'
import * as admin from './admin'

const global = {
  body: {
    // all devices have min width of 320px
    minWidth: '320px',
  },
  label: {
    _focusWithin: {
      outline: 'none !important',
    },
  },
}

// Chakra Breadcrumb component allows styling of BreadcrumbItem using Box props
// OGP DS breaks this implementation by not allowing these props to be passed down
delete ogpDsTheme.components.Breadcrumb

export const theme = extendTheme(
  ogpDsTheme,
  {
    colors: colors,
    shadows: shadows,
    space: spacing,
    fontSizes: typography.fontSize,
    fontWeights: typography.fontWeights,
    lineHeights: typography.lineHeights,
    letterSpacings: typography.letterSpacing,
    textStyles,
    components: {
      ...ogpDsTheme.components,
      ...features,
      ...components,
      ...admin,
    },
  },
  {
    styles: { global },
  },
)
