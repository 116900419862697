import { defineStyle, defineStyleConfig } from '@chakra-ui/react'

const brandWarning = defineStyle({
  background: 'yellow.50',
  border: '1px solid',
  borderColor: 'yellow.400',
  borderRadius: '4px',
})

export const Badge = defineStyleConfig({
  variants: { brandWarning },
})
