import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const helpers = createMultiStyleConfigHelpers(['checkCount'])

export const VerificationCount = helpers.defineMultiStyleConfig({
  baseStyle: {
    checkCount: {
      textStyle: 'body-2',
      color: 'base.content.medium',
      fontSize: '0.875rem',
    },
  },
})
