import { defineStyleConfig } from '@chakra-ui/react'

export const AlertNotification = defineStyleConfig({
  baseStyle: {
    alert: {
      textStyle: 'subhead-1',
      position: 'fixed',
      w: 'calc(100vw - 1rem)',
      top: '85dvh',
      left: '0.5rem',
      py: '1.25rem',
      zIndex: 9999,
    },
    alertLink: {
      color: 'blue.200',
      textDecoration: 'none',
    },
    closeButton: {
      color: 'base.content.inverse',
      size: 'sm',
      justifySelf: 'flex-end',
    },
  },
})
