import { memoize } from 'lodash'

/**
 * Wraps the decorated function with memoization, treating the first argument as the memo key.
 * Assumes the first argument to be a collection of section identifiers
 */
export function MemoizeBySections() {
  return function (
    _target: any,
    _propertyKey: string,
    descriptor?: PropertyDescriptor,
  ): any {
    if (!descriptor) {
      return null
    }
    const childFunction = descriptor.value
    descriptor.value = function (...args: any[]) {
      const sections = args.at(0) as string[]
      if (!sections)
        throw new Error('decorator requires sections as the first argument')
      if (!sections.length)
        throw new Error('sections argument must contain at least one element')
      const memoizedChildFunction = memoize(childFunction, (_args: unknown) => {
        return sections
      })
      const res = memoizedChildFunction.apply(this, args)
      return res
    }
    return descriptor
  }
}
