import { getBaseUrl } from './getBaseUrl'

export const isRelativeUrl = (url: string) => {
  const baseUrl = getBaseUrl()
  try {
    const normalizedUrl = new URL(url, baseUrl)
    return new URL(baseUrl).origin === normalizedUrl.origin
  } catch (e) {
    return false
  }
}

export const getRedirectPath = (defaultPath: string, callbackUrl?: string) => {
  // only allow callback URL to be used for redirect if it is a relative URL
  return callbackUrl && isRelativeUrl(callbackUrl) ? callbackUrl : defaultPath
}
