import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const helpers = createMultiStyleConfigHelpers([
  'iconContainer',
  'resultContent',
])

export const SearchContainer = helpers.defineMultiStyleConfig({
  baseStyle: {
    iconContainer: {
      w: '2rem',
      h: '2rem',
      borderRadius: '50%',
      bg: 'base.canvas.brand-subtle',
      mr: '1rem',
      justifyContent: 'center',
      alignItems: 'center',
    },
    resultContent: {
      maxW: 'calc(100% - 3rem)',
      alignItems: 'flex-start',
      spacing: '0.25rem',
    },
  },
})
