import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const helpers = createMultiStyleConfigHelpers(['clickable'])

export const IssueCard = helpers.defineMultiStyleConfig({
  baseStyle: {
    clickable: {
      _active: {
        WebkitTapHighlightColor: 'transparent',
      },
      position: 'absolute',
      top: '0',
      left: '0',
      w: '80%',
      h: '100%',
      cursor: 'pointer',
    },
  },
})
