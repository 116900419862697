import { defineStyle, defineStyleConfig } from '@chakra-ui/react'

const blue = defineStyle({
  bg: 'interaction.main-subtle.default',
  color: 'interaction.main.default',
  textStyle: 'subhead-2',
  py: '0.25rem',
  px: '0.5rem',
  w: 'max-content',
  borderRadius: '0.25rem',
})

const yellow = defineStyle({
  bg: 'yellow.50',
  color: 'standard.black',
  textStyle: 'subhead-2',
  py: '0.25rem',
  px: '0.5rem',
  w: 'max-content',
  borderRadius: '0.25rem',
})

const navy = defineStyle({
  bg: 'utility.feedback.info-subtle',
  color: 'base.content.default',
  textStyle: 'subhead-2',
  py: '0.25rem',
  px: '0.5rem',
  w: 'max-content',
  borderRadius: '0.25rem',
})

export const ListStatusIndicator = defineStyleConfig({
  variants: { blue, yellow, navy },
})
