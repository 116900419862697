import { useCallback, useEffect, useState } from 'react'
import { Box } from '@chakra-ui/react'
import { Banner } from '@opengovsg/design-system-react'
import { BiRefresh } from 'react-icons/bi'
import { REQUIRE_UPDATE_EVENT } from '~/constants'

export const AppVersionModalBanner = () => {
  const [requireUpdate, setRequireUpdate] = useState(false)

  const handleRequireUpdateEvent = useCallback(() => {
    setRequireUpdate(true)
  }, [])

  const onRefresh = useCallback(() => {
    window.location.reload()
  }, [])

  useEffect(() => {
    window.addEventListener(REQUIRE_UPDATE_EVENT, handleRequireUpdateEvent)
    return () => {
      window.removeEventListener(REQUIRE_UPDATE_EVENT, handleRequireUpdateEvent)
    }
  }, [handleRequireUpdateEvent])

  return (
    <>
      {requireUpdate && (
        <Box onClick={onRefresh} cursor="pointer">
          <Banner variant="warn" icon={BiRefresh}>
            An update is available. Click here to refresh the page.
          </Banner>
        </Box>
      )}
    </>
  )
}
