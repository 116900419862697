import { SectionVerificationCount } from '~/components/Header'
import { Lookup } from '~/server/modules/list/types'
import { MemoizeBySections } from '~/utils/decorators/MemoizeBySection'
import { ListTemplateView } from './ListTemplateView'
import { RecordView } from './RecordItemView'
import { isEmpty } from 'lodash'

// ListTemplateResponseDto fetched from a recursive query
// Cannot rely on Lookup['listTemplate'] to provided the correct type
export class ListLookupView {
  private readonly rootListTemplateInstance: ListTemplateView
  private readonly submissionsInstance: SubmissionView[]
  private readonly rootListInstance: ListView
  constructor(listLookupData: Lookup) {
    this.rootListTemplateInstance = new ListTemplateView(
      listLookupData.listTemplate,
    )
    this.submissionsInstance = sortBySubmissionTimeDesc(
      listLookupData.submissions.map(
        (submission) => new SubmissionView(submission),
      ),
    )
    const {
      listTemplate: _listTemplate,
      submissions: _submissions,
      ...rest
    } = listLookupData
    this.rootListInstance = new ListView(rest)
  }
  get ongoingSubmission() {
    return (
      this.submissions.find((submission) => !submission.checkSubmitted) ?? null
    )
  }
  // TODO: rename to latestCompletedSubmission
  get previousSubmission() {
    return this.ongoingSubmission
      ? this.submissions.at(1) ?? null
      : this.latestSubmission ?? null
  }
  get latestSubmission() {
    return this.submissions.at(0) ?? null
  }
  get rootListTemplate() {
    return this.rootListTemplateInstance
  }
  get submissions() {
    return this.submissionsInstance
  }
  get rootList() {
    return this.rootListInstance
  }
  // slice to remove the root list template id
  @MemoizeBySections()
  public getSectionViewsById(sections: string[]) {
    if (!sections.length)
      throw new Error('provide a valid list of sections to traverse')
    const navigated = [this.rootListTemplate]
    let currentListTemplate = this.rootListTemplate
    sections.slice(1, sections.length).forEach((listTemplateId) => {
      const foundSection = currentListTemplate.sections.find(
        (l) => listTemplateId === l.id,
      )
      if (!foundSection)
        throw new Error(
          `could not locate section for listTemplateId ${listTemplateId}`,
        )
      currentListTemplate = new ListTemplateView(foundSection)
      navigated.push(currentListTemplate)
    })
    return navigated
  }

  @MemoizeBySections()
  public getCurrentSectionListTemplate = (sections: string[]) => {
    if (!sections.length)
      throw new Error('provide a valid list of sections to traverse')
    return this.getSectionViewsById(sections).at(-1) as ListTemplateView
  }

  @MemoizeBySections()
  public getRootListTemplate = (sections: string[]) => {
    if (!sections.length)
      throw new Error('provide a valid list of sections to traverse')
    return this.getSectionViewsById(sections).at(0) as ListTemplateView
  }

  @MemoizeBySections()
  public isCurrentSectionListRoot = (sections: string[]) => {
    return (
      this.getCurrentSectionListTemplate(sections).id ===
      this.rootListTemplate.id
    )
  }

  @MemoizeBySections()
  public getLastKnownSubmission = (): {
    submitter: string | null
    submitted: Date | null
    recordsUniqueIdentifierMap: { [key: string]: RecordView }
  } | null => {
    const submission = this.submissions.find(
      (submission) => submission.checkSubmitted,
    )
    return submission
      ? {
          submitted: submission.submittedAt,
          submitter: submission.submitter?.name ?? null,
          recordsUniqueIdentifierMap: submission.recordsUniqueIdentifierMap,
        }
      : null
  }

  @MemoizeBySections()
  private getAllRecordsCurrentSection = (sections: string[]) => {
    const uniqueItemIdentifierToItemName: { [key: string]: string } = {}
    this.getCurrentSectionListTemplate(sections).items.forEach(
      (item) =>
        (uniqueItemIdentifierToItemName[item.uniqueItemIdentifier] = item.name),
    )
    if (!this.latestSubmission) return []
    return Object.values(
      this.latestSubmission.recordsUniqueIdentifierMap,
    ).filter((record) =>
      Boolean(record.uniqueItemIdentifier in uniqueItemIdentifierToItemName),
    )
  }

  @MemoizeBySections()
  public getRecordsInSubmissionByUniqueItemIdentifier = (
    sections: string[],
  ) => {
    const recordsInCurrentSection = this.getAllRecordsCurrentSection(sections)
    if (!recordsInCurrentSection.length) return {}
    return recordsInCurrentSection.reduce(
      (acc, v) => {
        acc[v.uniqueItemIdentifier] = v
        return acc
      },
      {} as Record<string, RecordView>,
    )
  }
}

export class SubmissionView {
  public readonly submission: Lookup['submissions'][number]
  public readonly recordsUniqueIdentifierMap: { [key: string]: RecordView }
  constructor(submission: Lookup['submissions'][number]) {
    this.submission = submission
    this.recordsUniqueIdentifierMap = {}
    submission.records.forEach((record) => {
      const recordView = new RecordView(record)
      this.recordsUniqueIdentifierMap[record.uniqueRecordIdentifier] =
        recordView
    })
  }
  get latestInterimSubmission() {
    return this.submission.interimSubmissionTimestamps.at(-1) ?? null
  }
  get checkSubmitted() {
    return this.submission.submitted
  }
  get submitter() {
    return this.submission.submitter
  }
  get submissionCreatedAt() {
    return this.submission.createdAt
  }
  get submittedAt() {
    return this.submission.submitted
  }

  get id() {
    return this.submission.id
  }

  public containsIncompleteRecordsOrSections = (
    listTemplateView?: ListTemplateView,
  ): boolean => {
    const nestedSections = listTemplateView
      ?.getNestedSections()
      .map((section) => new ListTemplateView(section))

    const nestedItems = new Set(
      listTemplateView
        ?.getNestedItems()
        .map(({ uniqueItemIdentifier }) => uniqueItemIdentifier),
    )
    const hasPendingSections = nestedSections?.some((section) => {
      return this.containsIncompleteRecordsOrSections(section)
    })
    const hasPendingRecords = Object.values(
      this.recordsUniqueIdentifierMap,
    ).some(
      (record) =>
        (!record.isVerified ||
          (record.item.isPhotoRequired && isEmpty(record.images))) &&
        nestedItems.has(record.uniqueItemIdentifier),
    )
    return hasPendingSections || hasPendingRecords
  }

  @MemoizeBySections()
  public getItemVerificationCountsForSection = (
    recordsByUniqueItemIdentifier: Record<string, RecordView>,
    sectionListView: ListTemplateView,
  ): SectionVerificationCount => {
    const allItemsInSection = sectionListView.getNestedItems()
    const numItems = allItemsInSection.length
    return {
      verified: allItemsInSection.filter(
        (nestedItem) =>
          recordsByUniqueItemIdentifier[nestedItem.uniqueItemIdentifier]
            ?.isVerified,
      ).length,
      total: numItems,
    }
  }
}

export class ListView {
  private readonly list: Omit<Lookup, 'listTemplate' | 'submissions'>
  constructor(list: Omit<Lookup, 'listTemplate' | 'submissions'>) {
    this.list = list
  }
  get id() {
    return this.list.id
  }
  get name() {
    return this.list.name
  }
}

export const sortBySubmissionTimeDesc = (submissions: SubmissionView[]) => {
  return submissions.sort(
    (a, b) => b.submissionCreatedAt.getTime() - a.submissionCreatedAt.getTime(),
  )
}
