/**
 * Do not edit directly
 * Generated on Wed, 19 Apr 2023 03:37:26 GMT
 */

export const textStyles = {
  'responsive-display': {
    'heavy-1280': {
      fontWeight: 700,
      lineHeight: '4.5rem',
      fontSize: '4rem',
      letterSpacing: '-0.022em',
      fontFamily: 'body',
    },
    'heavy-480': {
      fontWeight: 700,
      lineHeight: '4rem',
      fontSize: '3.5rem',
      letterSpacing: '-0.022em',
      fontFamily: 'body',
    },
    heavy: {
      fontWeight: 600,
      lineHeight: '3rem',
      fontSize: '2.5rem',
      letterSpacing: '-0.022em',
      fontFamily: 'body',
    },
    'light-1280': {
      fontWeight: 300,
      lineHeight: '4.5rem',
      fontSize: '4rem',
      letterSpacing: '-0.022em',
      fontFamily: 'body',
    },
    'light-480': {
      fontWeight: 300,
      lineHeight: '4rem',
      fontSize: '3.5rem',
      letterSpacing: '-0.022em',
      fontFamily: 'body',
    },
    light: {
      fontWeight: 300,
      lineHeight: '3rem',
      fontSize: '2.5rem',
      letterSpacing: '-0.022em',
      fontFamily: 'body',
    },
  },
  'responsive-heading': {
    'heavy-1280': {
      fontWeight: 600,
      lineHeight: '3.5rem',
      fontSize: '3rem',
      letterSpacing: '-0.022em',
      fontFamily: 'body',
    },
    'heavy-480': {
      fontWeight: 600,
      lineHeight: '3rem',
      fontSize: '2.5rem',
      letterSpacing: '-0.022em',
      fontFamily: 'body',
    },
    heavy: {
      fontWeight: 600,
      lineHeight: '2.5rem',
      fontSize: '2rem',
      letterSpacing: '-0.022em',
      fontFamily: 'body',
    },
    'light-1280': {
      fontWeight: 300,
      lineHeight: '3.5rem',
      fontSize: '3rem',
      letterSpacing: '-0.022em',
      fontFamily: 'body',
    },
    'light-480': {
      fontWeight: 300,
      lineHeight: '3rem',
      fontSize: '2.5rem',
      letterSpacing: '-0.022em',
      fontFamily: 'body',
    },
    light: {
      fontWeight: 300,
      lineHeight: '2.5rem',
      fontSize: '2rem',
      letterSpacing: '-0.022em',
      fontFamily: 'body',
    },
  },
  h1: {
    fontWeight: 600,
    lineHeight: '3rem',
    fontSize: '2.5rem',
    letterSpacing: '-0.022em',
    fontFamily: 'body',
  },
  h2: {
    fontWeight: 600,
    lineHeight: '2.75rem',
    fontSize: '2.25rem',
    letterSpacing: '-0.022em',
    fontFamily: 'body',
  },
  h3: {
    fontWeight: 700,
    lineHeight: '2.25rem',
    fontSize: '1.75rem',
    letterSpacing: '-0.019em',
    fontFamily: 'body',
  },
  'h3-semibold': {
    fontSize: '1.75rem',
    fontWeight: 600,
    lineHeight: '2.25rem',
    letterSpacing: '-0.019em',
    fontFamily: 'body',
  },
  h4: {
    fontWeight: 600,
    lineHeight: '2rem',
    fontSize: '1.5rem',
    letterSpacing: '-0.019em',
    fontFamily: 'body',
  },
  h5: {
    fontWeight: 600,
    lineHeight: '1.75rem',
    fontSize: '1.25rem',
    letterSpacing: '-0.014em',
    fontFamily: 'body',
  },
  h6: {
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '1.125rem',
    letterSpacing: '-0.014em',
    fontFamily: 'body',
  },
  'h6-semibold': {
    fontSize: '1.125rem',
    fontWeight: 600,
    lineHeight: '1.5rem',
    letterSpacing: '-0.014em',
    fontFamily: 'body',
  },
  'subhead-1': {
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '1rem',
    letterSpacing: '-0.006em',
    fontFamily: 'body',
  },
  'subhead-2': {
    fontWeight: 500,
    lineHeight: '1.25rem',
    fontSize: '0.875rem',
    letterSpacing: '-0.006em',
    fontFamily: 'body',
  },
  'subhead-3': {
    fontWeight: 600,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    letterSpacing: '0.080em',
    fontFamily: 'body',
    textTransform: 'uppercase',
  },
  'body-1': {
    fontWeight: 400,
    lineHeight: '1.5rem',
    fontSize: '1rem',
    letterSpacing: '-0.006em',
    fontFamily: 'body',
  },
  'body-2': {
    fontWeight: 400,
    lineHeight: '1.25rem',
    fontSize: '0.875rem',
    letterSpacing: 0,
    fontFamily: 'body',
  },
  'body-3': {
    fontWeight: 400,
    lineHeight: '1rem',
    fontSize: '1rem',
    letterSpacing: '-0.006em',
    fontFamily: 'body',
  },
  'caption-1': {
    fontWeight: 500,
    lineHeight: '1rem',
    fontSize: '0.75rem',
    letterSpacing: 0,
    fontFamily: 'body',
  },
  'caption-2': {
    fontWeight: 400,
    lineHeight: '1rem',
    fontSize: '0.75rem',
    letterSpacing: 0,
    fontFamily: 'body',
  },
  'code-1': {
    fontWeight: 400,
    lineHeight: '1.25rem',
    fontSize: '0.875rem',
    letterSpacing: 0,
    fontFamily: 'code',
  },
  'code-2': {
    fontWeight: 400,
    lineHeight: '1rem',
    fontSize: '0.75rem',
    letterSpacing: 0,
    fontFamily: 'code',
  },
  subtext: {
    fontWeight: 500,
    lineHeight: '0.75rem',
    fontSize: '0.625rem',
    letterSpacing: 0,
    fontFamily: 'body',
  },
}
