import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const helpers = createMultiStyleConfigHelpers([
  'sectionCard',
  'sectionChevronIcon',
  'sectionName',
  'sectionInfo',
])

export const SectionCard = helpers.defineMultiStyleConfig({
  baseStyle: {
    sectionCard: {
      justifyContent: 'space-between',
    },
    sectionName: {
      textStyle: 'subhead-1',
      fontSize: '1rem',
    },
    sectionInfo: {
      flexDir: 'column',
      flexGrow: 1,
      justifyContent: 'center',
    },
  },
})
