import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const DEFAULT_STYLE = {
  bg: 'base.canvas.brand-subtle',
  color: 'base.content.default',
  cursor: 'default',
}

const helpers = createMultiStyleConfigHelpers(['label'])

const getColoursForScheme = (colorScheme: string, variant: string) => {
  switch (colorScheme) {
    case 'warning':
      return {
        bg: 'interaction.warning.default',
        color: DEFAULT_STYLE.color,
        ...(variant !== 'itemset' && {
          _active: {
            bgColor: 'interaction.warning.active',
          },
        }),
      }
    case 'success-subtle':
      return {
        bg: 'interaction.success-subtle.default',
        color: 'interaction.success.default',
        ...(variant !== 'itemset' && {
          _active: {
            bgColor: 'interaction.success-subtle.active',
          },
        }),
      }
    default:
      return DEFAULT_STYLE
  }
}

export const RecordLabel = helpers.defineMultiStyleConfig({
  baseStyle: ({ colorScheme, variant }) => ({
    label: {
      ...getColoursForScheme(colorScheme, variant),
      w: 'fit-content',
      py: '0.25rem',
      px: '0.5rem',
      alignItems: 'center',
      borderRadius: '0.25rem',
      borderColor: 'none',
      textStyle: 'caption-1',
      whiteSpace: 'pre-wrap',
      cursor: 'pointer',
      WebkitTapHighlightColor: 'transparent',
    },
  }),
  variants: {
    unclickable: {
      label: {
        cursor: 'none',
        pointerEvents: 'none',
      },
    },
    itemset: {
      label: {
        cursor: 'default',
      },
    },
    deleted: {
      label: {
        textColor: 'utility.feedback.critical',
        bgColor: 'interaction.critical-subtle.default',
        borderColor: 'interaction.critical-subtle.hover',
        borderStyle: 'solid',
        borderWidth: '1px',
        cursor: 'default',
      },
    },
  },
})
