import { Team } from '@prisma/client'
import { PropsWithChildren, createContext, useContext } from 'react'
import { useLocalstorageState } from 'rooks'
import { useUser } from '~/features/profile/api'

const VIEWING_TEAM_KEY = 'viewingTeam'

type TeamContextType = {
  currentTeam: Team | null
  setCurrentTeam: (team: Team) => void
}

export const TeamContext = createContext<TeamContextType | null>(null)

export const useCurrentTeam = () => {
  const {
    user: { userTeams },
  } = useUser()
  const context = useContext(TeamContext)
  if (!context) {
    throw new Error('Can only use TeamContext inside a TeamContextProvider')
  }

  const { currentTeam, setCurrentTeam } = context
  // if there is no current team present, defaults to the first team present
  if (!currentTeam) {
    const defaultTeam = userTeams[0].team
    setCurrentTeam(defaultTeam)
    return { currentTeam: defaultTeam, setCurrentTeam }
  }

  return { currentTeam, setCurrentTeam }
}

export const TeamContextProvider = ({ children }: PropsWithChildren) => {
  const [currentTeam, setCurrentTeam] = useLocalstorageState<Team>(
    VIEWING_TEAM_KEY,
    undefined,
  )

  return (
    <TeamContext.Provider value={{ currentTeam, setCurrentTeam }}>
      {children}
    </TeamContext.Provider>
  )
}
