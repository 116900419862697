import { defineStyleConfig } from '@chakra-ui/react'

export const EnclosedCard = defineStyleConfig({
  baseStyle: {
    bgColor: 'base.canvas.default',
    borderRadius: '0.5rem',
    borderColor: 'base.divider.medium',
    borderWidth: '1px',
    p: '1rem',
  },
  variants: {
    important: {
      borderLeft: '4px',
      borderLeftColor: 'interaction.critical.default',
    },
    warning: {
      borderLeft: '4px',
      borderLeftColor: 'interaction.warning.default',
    },
    neutral: {
      borderLeft: '4px',
      borderLeftColor: 'brand.secondary.200',
    },
  },
})
