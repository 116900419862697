import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const helpers = createMultiStyleConfigHelpers(['box', 'title', 'window'])

const light = helpers.definePartsStyle({
  box: {
    bgColor: 'base.canvas.alt',
  },
  title: {
    display: 'none',
  },
  window: {
    color: 'base.content.default',
  },
})

export const ShiftStatus = helpers.defineMultiStyleConfig({
  baseStyle: {
    box: {
      bgColor: 'interaction.sub.active',
      borderRadius: '0.5rem',
    },
    title: {
      color: 'slate.200',
    },
    window: {
      color: 'standard.white',
    },
  },
  variants: { light },
})
