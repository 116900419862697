import { defineStyleConfig } from '@chakra-ui/react'

export const MenuButton = defineStyleConfig({
  baseStyle: {
    minHeight: '100%',
    border: 'none',
    borderRadius: '0 0.25rem 0.25rem 0',
  },
  variants: {
    active: {
      bgColor: 'interaction.main.default',
      _focus: { bgColor: 'interaction.main.default' },
      _active: { bgColor: 'interaction.main.default' },
    },
    disabled: {
      bgColor: 'interaction.support.disabled',
      _focus: { bgColor: 'interaction.support.disabled' },
      _active: { bgColor: 'interaction.support.disabled' },
    },
  },
})
