import { theme } from '~/theme'
import { createElement } from 'react'
import { createStandaloneToast } from '@chakra-ui/react'
import { Toast } from '@opengovsg/design-system-react'

export const { toast, ToastContainer } = createStandaloneToast({
  theme,
  defaultOptions: {
    isClosable: true,
    duration: 2000,
    containerStyle: { zIndex: 3, mb: '5.5rem' },
    position: 'bottom',
    render: (props) => {
      return createElement(Toast, props)
    },
  },
})
