import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const helpers = createMultiStyleConfigHelpers(['root'])

export const AdminContainer = helpers.defineMultiStyleConfig({
  baseStyle: {
    root: {
      p: '1.5rem',
      mt: '1.5rem',
      display: 'flex',
      flexDir: 'column',
      position: 'relative',
      flexGrow: 1,
      borderTopLeftRadius: '1rem',
      borderTopRightRadius: '1rem',
      flex: '1 1 auto',
      bg: 'standard.white',
      w: '65rem',
      minW: '50rem',
      maxW: '100vw',
      overflowX: 'scroll',
    },
    rootWithoutTableLook: {
      p: '1.5rem',
      mt: '1.5rem',
      display: 'flex',
      flexDir: 'column',
      position: 'relative',
      flexGrow: 1,
      flex: '1 1 auto',
      w: '65rem',
      minW: '50rem',
      maxW: '100vw',
      overflowX: 'scroll',
    },
  },
})
