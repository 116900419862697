// import { type TRPC_ERROR_CODE_KEY } from '@trpc/server/rpc'
import { Component } from 'react'
import { TRPCWithErrorCodeSchema } from '~/utils/error'
import {
  type ErrorBoundaryProps,
  type ErrorBoundaryState,
} from './ErrorBoundary.types'
import Custom404 from '~/pages/404'
import { datadogRum } from '@datadog/browser-rum'
import { TRPCClientError } from '@trpc/client'
import { AppRouter } from '~/server/modules/_app'
import { ErrorCardWithMessage } from './ErrorCardWithMessage'
import { routeByWindow, StaticRoutes } from '~/utils/RouterNavigation'

/**
 * Does the following:
 * 1. Checks if this is a recognizable TRPCClientError
 * 1a. Not a TRPCClientError
 *     - Render fallback component or UnexpectedErrorCard
 * 1b. Is a TPRCClientError
 *     - Checks if its an UNAUTHORIZED error
 * 2a. Is an UNAUTHORIZED error, redirect to `/sign-in` page
 * 2b. Not a UNAUTHORIZED error, render fallback component or <ErrorComponent /> according to switch case error code
 */

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props)

    // Define a state variable to track whether is an error or not
    this.state = { hasError: false }
  }
  static getDerivedStateFromError(error: unknown) {
    // Update state so the next render will show the fallback UI
    return { hasError: true, error }
  }
  componentDidCatch(err: Error) {
    datadogRum.addError(err)
  }

  render() {
    const { children, fallback } = this.props
    const error = this.state.error as unknown as TRPCClientError<AppRouter>
    if (!this.state.hasError) return children // Check if the error is thrown
    const res = TRPCWithErrorCodeSchema.safeParse(error)
    if (!res.success) {
      return (
        fallback ?? (
          <ErrorCardWithMessage
            customTitle="Something went wrong!"
            customMessage="Please logout & login, clear your cookies, and sign into armoury again"
          />
        )
      )
    }
    switch (res.data.code) {
      case 'UNAUTHORIZED':
        routeByWindow(StaticRoutes.SIGN_IN)
        return
      case 'NOT_FOUND':
        return <Custom404 />
      default:
        return (
          <ErrorCardWithMessage
            customTitle={res.data.code}
            customMessage={res.data.message}
          />
        )
    }
  }
}

export default ErrorBoundary
