import { alertAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(alertAnatomy.keys)

const brandVariant = definePartsStyle({
  container: {
    border: 'none',
    borderRadius: '4px',
    background: 'slate.800',
  },
  title: {
    color: 'base.content.inverse',
  },
  description: {
    color: 'base.content.inverse',
  },
})

const warningHighlightVariant = definePartsStyle({
  container: {
    border: '1px solid',
    borderColor: 'yellow.400',
    borderRadius: '4px',
    background: 'yellow.50',
  },
})

const errorVariant = definePartsStyle({
  container: {
    border: '1px solid',
    borderColor: 'red.200',
    borderRadius: '4px',
    background: 'red.50',
  },
})

export const Alert = defineMultiStyleConfig({
  variants: {
    brand: brandVariant,
    warningHighlight: warningHighlightVariant,
    error: errorVariant,
  },
})
