import { createMultiStyleConfigHelpers } from '@chakra-ui/react'
import { STICKY_BUTTON_HEIGHT } from '~/features/list/submission/components/StickyButton'

const helpers = createMultiStyleConfigHelpers([
  'content',
  'body',
  'headerContainer',
  'headerText',
  'leftCloseButton',
  'rightCloseButton',
])

export const Drawer = helpers.defineMultiStyleConfig({
  baseStyle: {
    content: {
      height: '100dvh',
      minW: '320px',
    },
    headerContainer: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      background: 'base.canvas.brand-subtle',
      h: '3.5rem',
      borderBottom: '1px solid',
      borderBottomColor: 'brand.primary.100',
      px: { base: '1rem', md: '1.5rem' },
    },
    bodyContainer: {
      px: { base: '1rem', md: '1.5rem' },
      py: '1.5rem',
      overflowX: 'hidden',
    },
    headerText: {
      textStyle: 'subhead-1',
      minW: '15rem',
    },
    rightCloseButton: {
      background: 'none',
      boxSize: '1.5rem',
      '& svg': {
        boxSize: '0.75rem',
      },
      _focus: {
        outline: '0',
      },
    },
  },
  variants: {
    'sticky-button': {
      body: {
        overflow: 'scroll',
        height: `calc(100dvh - 3.5rem - ${STICKY_BUTTON_HEIGHT})`,
      },
    },
    'sticky-button-no-padding': {
      body: {
        overflow: 'scroll',
        height: `calc(100dvh - 3.5rem - ${STICKY_BUTTON_HEIGHT})`,
        p: 0,
      },
    },
  },
})
