import { Breadcrumb } from '@chakra-ui/react'
import { GetListTemplatePaths } from '~/server/modules/list/types'
import { BreadcrumbItemWrapper } from '~/components/BreadcrumbItemWrapper'
import { Path } from '~/server/modules/list/list.util'

export const getPathStringToItem = (
  listName: string,
  sectionPaths: Record<string, Path[]>,
  sections: string[],
  tag: string | null,
  separator = '/',
) => {
  const paths = getPathToItem(sectionPaths, sections, tag)
  return paths
    .map(({ name }, idx) => (idx === 0 ? `${listName} - ${name}` : name))
    .join(` ${separator} `)
}

const getPathToItem = (
  sectionPaths: Record<string, Path[]>,
  sections: string[],
  tag: string | null,
): Path[] => {
  const lastSection = sections.at(-1)
  if (!lastSection) return []
  const sectionPath = [...(sectionPaths[lastSection] ?? [])]
  if (sectionPath.length === 0)
    throw new Error('Could not find path for section')
  if (tag) {
    sectionPath.push({ id: `${lastSection}-itemset`, name: tag })
  }
  return sectionPath
}

export class ListTemplatePathsView {
  private readonly statesInstance: GetListTemplatePaths
  constructor(states: GetListTemplatePaths) {
    this.statesInstance = states
  }
  public getSectionPathBySectionId = (sectionId: string) => {
    return this.statesInstance[sectionId] ?? null
  }
  public getSectionCrumb = (
    sectionId: string,
    tag?: string | null,
  ): JSX.Element => {
    const path = this.getSectionPathBySectionId(sectionId)?.slice(1)
    if (tag) {
      path?.push({
        id: `${sectionId}-itemset`,
        name: tag,
      })
    }
    return path ? (
      <Breadcrumb
        fontWeight="400"
        textStyle="body-2"
        color="base.content.medium"
        opacity="0.75"
        spacing="0.2rem"
        separator="/"
        listProps={{
          style: {
            flexWrap: 'wrap',
            display: 'inline',
            verticalAlign: 'center',
          },
        }}
      >
        {path.map((p) => {
          return <BreadcrumbItemWrapper key={p.id} label={p.name} />
        })}
      </Breadcrumb>
    ) : (
      <></>
    )
  }
}
